export const environment = {
  production: true,
  appUrl: 'https://eldx.zonarsystems.net',
  authEnv: 'production',
  prefix: 'production',
  name: 'prod',
  region: 'NA',
  complianceApplicationId: 'f65b5dde-75d1-49c4-af51-81653dcd6f74',
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: 'SGCutlbOBHRkfMiSFWznutKLTIC4NmGN',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    applicationId: 'd866c783-abe1-4bbc-9f8b-45ad722d9a14',
    defaultZonarRole: '0213d92a-5bb0-4778-9338-657d3e057a00',
    useRefreshTokens: true
  },
  urls: {
    udlApi: `/udlapi`,
    baseApi: `https://api.zonarsystems.net`,
    externalApi: 'https://api.zonarsystems.net/core/v1beta2',
    complianceUrl: `https://compliance.zonarsystems.net`,
    driverLogsApi: '/driver-logs-api',
    legacyEntityApi: '/legacy-entity-api',
    hosReportApi: `/hos-report-api`,
    GTC4: `https://groundtrafficcontrol.zonarsystems.net/`
  },
  helpDocs: {
    UDL: `https://support.zonarsystems.net/hc/en-us/articles/4414469986701-Unidentified-Driver-Logs-UDLs-`,
    UDL_AutoAssignYM: `https://support.zonarsystems.net/hc/en-us/articles/16961143878925-Auto-Assigning-UDLs-in-Yards-or-Zones`,
    ELDx_helpDocs: `https://support.zonarsystems.net/hc/en-us/articles/24556147665293-Driver-Logs-for-ELD-New`,
    NDR: `https://support.zonarsystems.net/hc/en-us/articles/17999726420365`
  },
  hereMaps: {
    hereApiKey: undefined,
    hereV3ApiKey: undefined,
    useV3: true,
    // hereUrl: '/map-data/{s}/maptile/2.1' v2 url
    hereUrl: '/map-data-v3'
  },
  datadog: {
    applicationId: 'b3fd3a90-06ed-48ca-9023-9cba94d00a02',
    clientToken: 'pub8a652e107235c257e7f966cc72f1784e',
    site: 'us5.datadoghq.com',
    service: 'eldx',
  },
  devCycle: {
    clientId: 'dvc_client_b158c4f8_ed00_488f_a4d9_ec878fad4c6a_44848e0'
  },
  excludeAccounts: ['switest1', 'switest2', 'swi2288', 'swi8886']
};
